.container {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    padding: 15px;
    margin: 10px 0;
    border-radius: 5px;

    .remove-button {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
}

.save-button {
    text-align: center;
    margin-top: 10px;
}

.add-btn-container {
    margin-top: 10px;
    float: right;
}

.statistics {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    font-size: 17px;
}

.mac-container {
    width: 200px;
    margin-bottom: 30px;
    height: 30px;
}

.form-content {
    display: flex;
    gap: 10px;
}

.playlists-form-container {
    max-height: 343px;
    overflow: auto;
    margin-bottom: 20px;
}