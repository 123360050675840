.custom-mac-input-container {
    width: 100%;

    .mac-inp {
        height: 32px;
        width: 100%;
        padding: 4px 11px;
        background-color: #ffffff;
        color: rgba(0, 0, 0, 0.88);
        box-sizing: border-box;
        border-width: 1px;
        border-style: solid;
        border-color: #d9d9d9;
        border-radius: 6px;
        transition: all 0.2s;

        &:focus{
            border-color: #4096ff;
            box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
            outline: 0;
        }

        &:disabled{
            cursor: not-allowed;
            background-color: #f5f4f4;
            opacity: 0.5;
        }
    }
}