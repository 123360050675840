.save-button {
    text-align: center;
}

.add-button {
    float: right;
    margin-bottom: 20px;
}

.categories-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    &_categories {
        width: 350px;
    }
}

.form-content {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 20px;

}

.go-back-arrow {
    cursor: pointer;
}

.upload {
    cursor: pointer;
}

.page-top-content {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    align-items: center;
}

.category-name-content {
    display: flex;
    align-items: center;
    gap: 10px;
}

.file-chooser {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 50px;
    height: 50px;
    cursor: pointer;

    .plus{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .file-input {
        width: 50px;
        height: 50px;
        opacity: 0;

        cursor: pointer;
    }

    .delete {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        opacity: 0;
        color:antiquewhite;

        display: flex;
        justify-content: center;
        align-items: center;

        width: 50px;
        height: 50px;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .uploaded-image {
        width: 50px;
        height: 50px;
        object-fit: cover;

        &:hover {
            opacity: 0.5;
        }
    }
}

@media screen and (max-width:700px) {
    .form-content {
        gap: 0 !important;

        &_item {
            width: 100%;
        }
    }
}