.save-btn {
    text-align: center;
}

.add-button {
    float: right;
    margin-bottom: 20px;
}

.row-click {
    cursor: pointer;
}

.subreseller-data {
    background-color: rgb(230, 243, 255);

    td{
         background-color: rgb(230, 243, 255) !important;
         
         &:hover { 
            background-color: rgb(230, 243, 255) !important;
    }}
}