
.avatar{
    cursor: pointer;
    margin-right:24px;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 5px;
}

.profile-dropdown{
    min-width:110px  !important;
}

.submit-button{
    text-align: center;
}