.message-close{
    height: 0;
    transition: height 0.3s ease-in-out;
}

.message-open{
    text-align: center;
    width: 100%;
    display: flex;
    align-items:center;
    justify-content: center;
    height: 40px;
    // background-color: yellow;
    // margin-bottom: 10px;
    transition: height 0.3s ease-in-out;
}

.error-text{
    color: red;
}

.success-text{
    color: green;
}