.login-page {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .login-form {
        width: 350px;

        .login-form-button{
            width: 100%;
        }
    }
}